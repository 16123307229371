.footer-component {
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 120px;
  background-color: var(--bg-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: absolute;
  bottom: 0%;
}

.footer-component__copywrite {
  width: 100%;
}

.footer-component__copywrite a {
  color: #02b1c8;
  font-weight: 600;
}

.footer-component__copywrite a:hover {
  color: #016e7c;
}

.footer-component__legal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.footer-component__legal a {
  text-decoration: none;
  padding-left: 30px;
  color: #525f7f;
}

.footer-component__legal a:hover {
  color: #02b1c8;
}

@media(max-width: 768px) {

  .footer-component,
  .footer-component__legal {
    flex-direction: column;
  }

  .footer-component__legal a {
    font-size: small 0.875rem !important;
    padding-left: 0;
  }

  .text {
    font-size: 0.875rem !important;
  }
}

/* 

@media(min-width: 1024px) {
  .footer-component {
    padding: 1rem 4rem;
  }

  .footer-component__copywrite {
    width: 50%;
  }

  .footer-component__legal {
    width: 50%;
  }
}


@media(min-width: 1200px) {
  .footer-component {
    padding: 1rem 5rem;
  }

  .footer-component__copywrite {
    width: 60%;
  }

  .footer-component__legal {
    width: 40%;
  }
} */
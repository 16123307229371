.login-container {
  /* padding-bottom: 2rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  max-width: 475px;
  min-width: 350px;
  margin: auto;
}

.MuiInputBase-adornedEnd, .MuiFilledInput-adornedEnd, .MuiFilledInput-underline:before {
  background-color: #E8F0FE !important;
  border-bottom: 0 !important;
}

.header-container, .body-container {
  margin: 2% 5%;
  text-align: center;
}

/* @media (min-width: 1024px) {
  .login-container > .header-container, .body-container {
    margin: 8% 5%;
  }
}

@media (min-width: 1224px) {
  .login-container > .header-container, .body-container {
    margin: 3.5% 5%;
  }
} */
.gradient-container {
  z-index: 0;
  position: absolute;
  top: 0%;
  width: 100% !important;
  height: 22em !important;
}

.bg-gradient {
  width: 100%;
  height: 100%;
  background: var(--bg-gradient);
}

.fill-default {
  fill: var(--bg-color);

}
.separator {
  background: var(--bg-gradient);
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  transform: translateZ(0);
  overflow: hidden;
  pointer-events: none;
}
.separator svg {
  position: absolute;
  pointer-events: none;
}

.separator-bottom {
  top: auto;
  bottom: 0;
}

.separator-bottom svg {
  bottom: 0;
}
.separator-skew {
  height: 60px;
}
@media (min-width: 1200px) {
  .separator-skew {
    height: 70px;
  }
}
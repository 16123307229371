@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
.header-component {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 5rem;
  background: linear-gradient(87deg, #02b1c8, #025fc8);
  padding: 1rem 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-component>* {
  height: 100%;
}
.language-component {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.btn {
    border: 1px solid #084454;
    border-radius: 100px;
    cursor: pointer;
    padding: 12px 10px;
    background-color: #aaa9a9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn.selected {
    background-color: white;
    border: 1px solid #084454;
}
.footer-component {
  min-height: 100px;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 120px;
  background-color: var(--bg-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  position: absolute;
  bottom: 0%;
}

.footer-component__copywrite {
  width: 100%;
}

.footer-component__copywrite a {
  color: #02b1c8;
  font-weight: 600;
}

.footer-component__copywrite a:hover {
  color: #016e7c;
}

.footer-component__legal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
}

.footer-component__legal a {
  text-decoration: none;
  padding-left: 30px;
  color: #525f7f;
}

.footer-component__legal a:hover {
  color: #02b1c8;
}

@media(max-width: 768px) {

  .footer-component,
  .footer-component__legal {
    flex-direction: column;
  }

  .footer-component__legal a {
    font-size: small 0.875rem !important;
    padding-left: 0;
  }

  .text {
    font-size: 0.875rem !important;
  }
}

/* 

@media(min-width: 1024px) {
  .footer-component {
    padding: 1rem 4rem;
  }

  .footer-component__copywrite {
    width: 50%;
  }

  .footer-component__legal {
    width: 50%;
  }
}


@media(min-width: 1200px) {
  .footer-component {
    padding: 1rem 5rem;
  }

  .footer-component__copywrite {
    width: 60%;
  }

  .footer-component__legal {
    width: 40%;
  }
} */
.login-container {
  /* padding-bottom: 2rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  max-width: 475px;
  min-width: 350px;
  margin: auto;
}

.MuiInputBase-adornedEnd, .MuiFilledInput-adornedEnd, .MuiFilledInput-underline:before {
  background-color: #E8F0FE !important;
  border-bottom: 0 !important;
}

.header-container, .body-container {
  margin: 2% 5%;
  text-align: center;
}

/* @media (min-width: 1024px) {
  .login-container > .header-container, .body-container {
    margin: 8% 5%;
  }
}

@media (min-width: 1224px) {
  .login-container > .header-container, .body-container {
    margin: 3.5% 5%;
  }
} */
df-messenger {
  --df-messenger-button-titlebar-color: #02b1c8;
  right: 50%;
}

.df-messenger-wrapper {
  right: 50% !important;
}
.gradient-container {
  z-index: 0;
  position: absolute;
  top: 0%;
  width: 100% !important;
  height: 22em !important;
}

.bg-gradient {
  width: 100%;
  height: 100%;
  background: var(--bg-gradient);
}

.fill-default {
  fill: var(--bg-color);

}
.separator {
  background: var(--bg-gradient);
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  transform: translateZ(0);
  overflow: hidden;
  pointer-events: none;
}
.separator svg {
  position: absolute;
  pointer-events: none;
}

.separator-bottom {
  top: auto;
  bottom: 0;
}

.separator-bottom svg {
  bottom: 0;
}
.separator-skew {
  height: 60px;
}
@media (min-width: 1200px) {
  .separator-skew {
    height: 70px;
  }
}
:root {
  --bg-color: #084454;
  --bg-gradient: linear-gradient(87deg,#02b1c8,#025fc8);
  --text: #8898aa;
  --text-dark: #212529;
  --text-light: #ced4da;
}

* {
  z-index: 1;
}

body {
  margin: 0%;
}

.app-component {
  height: 100vh;
  background: var(--bg-color);
}

.text {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  margin: 0%;
  font-size: 1rem;
  color: var(--text);
}

.text--light {
  color: var(--text-light);
}

.text--white {
  color: white;
}

.text--black {
  color: black;
}

.text--dark {
  color: var(--text-dark);
}

.text--large {
  font-size: 1.625rem;
}

.text--small {
  font-size: 0.8rem;
}

.mt-5 {
  margin-top: 5%;
}

.mb-5 {
  margin-bottom: 5%;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import './separator.css';
:root {
  --bg-color: #084454;
  --bg-gradient: linear-gradient(87deg,#02b1c8,#025fc8);
  --text: #8898aa;
  --text-dark: #212529;
  --text-light: #ced4da;
}

* {
  z-index: 1;
}

body {
  margin: 0%;
}

.app-component {
  height: 100vh;
  background: var(--bg-color);
}

.text {
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  margin: 0%;
  font-size: 1rem;
  color: var(--text);
}

.text--light {
  color: var(--text-light);
}

.text--white {
  color: white;
}

.text--black {
  color: black;
}

.text--dark {
  color: var(--text-dark);
}

.text--large {
  font-size: 1.625rem;
}

.text--small {
  font-size: 0.8rem;
}

.mt-5 {
  margin-top: 5%;
}

.mb-5 {
  margin-bottom: 5%;
}
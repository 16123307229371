.language-component {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.btn {
    border: 1px solid #084454;
    border-radius: 100px;
    cursor: pointer;
    padding: 12px 10px;
    background-color: #aaa9a9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn.selected {
    background-color: white;
    border: 1px solid #084454;
}